import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TrendingUp, Award, Users, ChevronDown, Sun, Moon } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import RegistrationForm from './components/RegistrationForm';


const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(prefersDark);
  }, []);

  const features = [
    { icon: <TrendingUp className="w-8 h-8 text-blue-500" />, title: "Test Your Skills", description: "Compete as a team against other traders in our community." },
    { icon: <Award className="w-8 h-8 text-green-500" />, title: "₦200k Prize Pool", description: "The best traders and the best teams get the best prizes." },
    { icon: <Users className="w-8 h-8 text-purple-500" />, title: "Network", description: "Connect with fellow crypto degens and join a vibrant community." },
  ];

  const aboutSections = [
    { title: "Origins", content: "ACE began in late 2019 as a small WhatsApp group of web3 enthusiasts from CMUL MBBS '24." },
    { title: "Why", content: "Sharing alpha and expertise meant members did 5-20x more than they could on their own. Fading became impossible." },
    { title: "Today's ACE", content: "Now a thriving community with hundreds of members, ACE is still driven by collaboration and shared success." },
    { title: "Join us", content: "Grab your chance to be part of ACE and level up your web3 game." },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.3 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const toggleRegistrationForm = () => {
    setShowRegistrationForm(!showRegistrationForm);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-b from-purple-900 via-gray-900 to-black text-white' : 'bg-gradient-to-b from-purple-100 via-gray-100 to-white text-gray-900'} transition-colors duration-300`}>
      <div className="container mx-auto px-4 py-16">
        <AnimatePresence mode="wait">
          {!showRegistrationForm ? (
            <motion.div
              key="landing"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={containerVariants}
              className="text-center mb-16"
            >
              <motion.h1 
                className="text-4xl md:text-6xl font-extrabold mb-4 leading-tight"
                variants={itemVariants}
              >
                ACE Community
                <span className={`block text-transparent bg-clip-text ${isDarkMode ? 'bg-gradient-to-r from-blue-400 to-purple-500' : 'bg-gradient-to-r from-blue-600 to-purple-700'} pb-4`}>
                  September Trading Contest
                </span>
              </motion.h1>
              <motion.p 
                className={`text-xl md:text-2xl mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
                variants={itemVariants}
              >
                Showcase your degen skills and win amazing prizes
              </motion.p>
              <motion.button
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`bg-gradient-to-r ${isDarkMode ? 'from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700' : 'from-blue-600 to-purple-700 hover:from-blue-700 hover:to-purple-800'} text-white font-bold py-3 px-6 md:py-4 md:px-8 rounded-full text-lg md:text-xl transition duration-300 shadow-lg`}
                onClick={toggleRegistrationForm}
              >
                Join the Contest
              </motion.button>

              <motion.div 
                className="grid md:grid-cols-3 gap-8 my-16"
                variants={containerVariants}
              >
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    variants={itemVariants}
                    className={`${isDarkMode ? 'bg-gray-800 bg-opacity-75' : 'bg-white'} p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300`}
                  >
                    <div className="flex items-center mb-4">
                      {feature.icon}
                      <h3 className="text-xl font-semibold ml-4">{feature.title}</h3>
                    </div>
                    <p className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>{feature.description}</p>
                  </motion.div>
                ))}
              </motion.div>

              <motion.div
                variants={itemVariants}
                className={`${isDarkMode ? 'bg-gray-800 bg-opacity-75' : 'bg-white'} p-8 md:p-12 rounded-lg shadow-lg max-w-4xl mx-auto`}
              >
                <h2 className={`text-3xl md:text-4xl font-bold mb-10 pb-4 text-center text-transparent bg-clip-text ${
                  isDarkMode ? 'bg-gradient-to-r from-blue-400 to-purple-500' : 'bg-gradient-to-r from-blue-600 to-purple-700'
                }`}>
                  About ACE Community
                </h2>
                <div className="space-y-6">
                  {aboutSections.map((section, index) => (
                    <motion.div
                      key={index}
                      className={`p-6 rounded-lg transition-all duration-300 ${
                        isDarkMode 
                          ? hoveredSection === index ? 'bg-gray-700' : 'hover:bg-gray-700' 
                          : hoveredSection === index ? 'bg-gray-100' : 'hover:bg-gray-100'
                      } cursor-pointer text-center`}
                      onMouseEnter={() => setHoveredSection(index)}
                      onMouseLeave={() => setHoveredSection(null)}
                      whileHover={{ scale: 1.02 }}
                    >
                      <h3 className={`text-xl font-semibold mb-2 ${
                        isDarkMode ? 'text-blue-300' : 'text-blue-600'
                      }`}>{section.title}</h3>
                      <AnimatePresence>
                        {(hoveredSection === index || !isDesktop) && (
                          <motion.p
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'} leading-relaxed`}
                          >
                            {section.content}
                          </motion.p>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {isDesktop && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1, duration: 1.5 }}
                  className="text-center mt-12"
                >
                  <ChevronDown className={`w-12 h-12 mx-auto ${isDarkMode ? 'text-purple-400' : 'text-purple-600'} animate-bounce`} />
                </motion.div>
              )}
            </motion.div>
          ) : (
            <motion.div
              key="registration"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={containerVariants}
            >
              <RegistrationForm isDarkMode={isDarkMode} />
              <motion.button
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`mt-8 bg-gradient-to-r ${isDarkMode ? 'from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700' : 'from-blue-600 to-purple-700 hover:from-blue-700 hover:to-purple-800'} text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 shadow-lg`}
                onClick={toggleRegistrationForm}
              >
                Back to Home
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <button
        onClick={() => setIsDarkMode(!isDarkMode)}
        className="fixed top-4 right-4 p-2 rounded-full bg-opacity-20 hover:bg-opacity-30 transition-colors duration-200"
        aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
      >
        {isDarkMode ? <Sun className="w-6 h-6 text-yellow-400" /> : <Moon className="w-6 h-6 text-gray-700" />}
      </button>
    </div>
  );
};

export default App;
