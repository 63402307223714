import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { UserCircle, Phone, TrendingUp, Wallet, Hash, Mail, X } from 'lucide-react';
import axios from 'axios';

const MarkdownRenderer = ({ content }) => {
    const lines = content.split('\n');
  
    const renderLine = (line, index) => {
      if (line.startsWith('# ')) {
        return <h1 key={index} className="text-2xl font-bold mt-6 mb-4 text-blue-400">{line.substring(2)}</h1>;
      } else if (line.startsWith('## ')) {
        return <h2 key={index} className="text-xl font-semibold mt-4 mb-2 text-blue-300">{line.substring(3)}</h2>;
      } else if (line.startsWith('### ')) {
        return <h3 key={index} className="text-lg font-medium mt-3 mb-1 text-blue-200">{line.substring(4)}</h3>;
      } else if (line.match(/^\d+\.\s/)) {
        // Numbered list item
        const content = line.substring(line.indexOf('.') + 1).trim();
        return <div key={index} className="flex mb-2">
          <span className="mr-2">{line.split('.')[0]}.</span>
          <span>{content}</span>
        </div>;
      } else if (line.startsWith('   - ') || line.startsWith('    - ')) {
        // Indented bullet point
        return <div key={index} className="ml-8 mb-1 flex">
          <span className="mr-2">•</span>
          <span>{line.trim().substring(2)}</span>
        </div>;
      } else if (line.trim() === '') {
        return <br key={index} />;
      } else {
        return <p key={index} className="mb-2">{line}</p>;
      }
    };
  
    return (
      <div className="markdown-content text-gray-200 text-sm">
        {lines.map(renderLine)}
      </div>
    );
  };

const RulesModal = ({ isOpen, onClose, contestRules }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="bg-gray-800 rounded-lg shadow-xl p-6 max-w-2xl w-full max-h-[80vh] overflow-y-auto"
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-blue-400">Contest Rules</h2>
            <button onClick={onClose} className="p-1 rounded-full hover:bg-gray-700">
              <X className="w-6 h-6 text-gray-300" />
            </button>
          </div>
          <MarkdownRenderer content={contestRules} />
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

const RegistrationForm = ({ isDarkMode }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    whatsappNumber: '',
    tradingStyle: '',
    experience: '',
    solWallet: '',
    txHash: '',
    agreeToRules: false,
  });

  const [errors, setErrors] = useState({});
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ 
      ...formData, 
      [name]: type === 'checkbox' ? checked : value 
    });
    validateField(name, type === 'checkbox' ? checked : value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    switch (name) {
      case 'firstName':
      case 'lastName':
        newErrors[name] = value.trim() === '' ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required` : '';
        break;
      case 'email':
        newErrors.email = !/\S+@\S+\.\S+/.test(value) ? 'Invalid email address' : '';
        break;
      case 'whatsappNumber':
        newErrors.whatsappNumber = !/^\+?[0-9]{10,14}$/.test(value) ? 'Invalid WhatsApp number' : '';
        break;
      case 'tradingStyle':
        newErrors.tradingStyle = value === '' ? 'Please select a trading style' : '';
        break;
      case 'experience':
        newErrors.experience = value === '' ? 'Please rate your experience' : '';
        break;
      case 'solWallet':
        newErrors.solWallet = !/^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(value) ? 'Invalid Solana wallet address' : '';
        break;
      case 'txHash':
        newErrors.txHash = value.trim() === '' ? 'Transaction hash is required' : '';
        break;
      case 'agreeToRules':
        newErrors.agreeToRules = !value ? 'You must agree to the rules' : '';
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  


  const handleSubmit = async (e) => {
     e.preventDefault();
     const formErrors = validateForm();
     if (Object.keys(formErrors).length === 0) {
       setIsSubmitting(true);
       setSubmitError(null);
       try {
         const response = await axios.post('http://173.212.244.101/api/register', {
           first_name: formData.firstName,
           last_name: formData.lastName,
           email: formData.email,
           whatsapp_number: formData.whatsappNumber,
           trading_style: formData.tradingStyle,
           experience: parseInt(formData.experience),
           sol_wallet: formData.solWallet,
           tx_hash: formData.txHash,
           agree_to_rules: formData.agreeToRules
         });
         console.log('Registration successful:', response.data);
         setSubmitSuccess(true);
       } catch (error) {
         console.error('Registration failed:', error);
         setSubmitError(error.response?.data?.detail || 'An error occurred during registration. Please try again.');
       } finally {
         setIsSubmitting(false);
       }
     } else {
       setErrors(formErrors);
     }
   };

  const validateForm = () => {
    const formErrors = {};
    if (formData.firstName.trim() === '') formErrors.firstName = 'First name is required';
    if (formData.lastName.trim() === '') formErrors.lastName = 'Last name is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = 'Invalid email address';
    if (!/^\+?[0-9]{10,14}$/.test(formData.whatsappNumber)) formErrors.whatsappNumber = 'Invalid WhatsApp number';
    if (formData.tradingStyle === '') formErrors.tradingStyle = 'Please select a trading style';
    if (formData.experience === '') formErrors.experience = 'Please rate your experience';
    if (!/^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(formData.solWallet)) formErrors.solWallet = 'Invalid Solana wallet address';
    if (formData.txHash.trim() === '') formErrors.txHash = 'Transaction hash is required';
    if (!formData.agreeToRules) formErrors.agreeToRules = 'You must agree to the rules';
    return formErrors;
  };

  const isFormValid = () => {
    return Object.values(errors).every(x => x === '') &&
           Object.values(formData).every(x => x !== '') &&
           formData.agreeToRules;
  };

  const contestRules = `
# ACE Community Shitcoin Trading Contest Rules

## Eligibility and Registration

1. The contest is open to the first 50 registered participants.
2. Registration opens immediately and closes on Saturday, September 14, 2024, at 23:59 UTC.
3. The contest will proceed only if a minimum of 25 participants register. In the event of insufficient registrations, the contest will be cancelled, and all registration fees will be refunded.
4. To register, participants must pay a registration fee of 0.05 SOL.
   - Send the registration fee to the following Solana address: F6MYg38wN8juykKvHv6da1w82xqMF43bGjbR4cMS89et
   - The registration fee will be returned to participants at the start of the contest and will serve as the initial trading funds.
5. Participants must provide a valid transaction hash for the registration fee payment during the registration process.

## Contest Structure

1. Participants will be divided into 5 teams, with 5-10 members per team.
2. Team assignments will be made by the contest organizers to ensure a balanced distribution of experience levels across teams.
3. Team assignments are final and cannot be changed once the contest begins.

## Trading Rules

1. Each participant must use a new, empty Solana wallet for the contest. This wallet must have no prior transaction history.
2. The wallet address used for trading must be different from the one used to send the registration fee.
3. During the contest period, only SWAP transactions are permitted within the designated trading wallet.
4. Pumpfun Trading is allowed within the contest rules.
5. Participants are strictly prohibited from:
   - Sending funds out of the contest wallet
   - Sending additional funds into the contest wallet
   - Engaging in any transactions other than SWAP transactions
6. Any violation of these trading rules will result in immediate disqualification.

## Contest Duration

The exact start and end dates of the contest will be announced to all registered participants at least 48 hours before the contest begins.

## Prizes

Total Prize Pool: ₦200,000

1. Individual Prizes:
   - Top 5 Traders Overall: ₦10,000 each

2. Team Prizes:
   - First Place Team: ₦100,000 (split among team members, excluding the lowest-performing member)
   - Second Place Team: ₦50,000 (split among team members, excluding the lowest-performing member)

3. Prize Distribution:
   - All prizes will be sent to the Solana wallet address used for trading during the contest.
   - Prize amounts are in Nigerian Naira (₦) and will be converted to SOL at the prevailing exchange rate at the time of distribution.

## Judging Criteria

1. Individual performance will be judged based on the percentage increase in wallet value from the starting amount.
2. Team performance will be calculated as the average percentage increase of all team members' wallets.
3. In the event of a tie, the participant or team with the higher number of successful trades will be ranked higher.

## General Rules

1. The contest is designed to promote collaboration. Participants are encouraged to share information and strategies within their assigned teams.
2. All trading activities must comply with applicable laws and regulations.
3. The contest organizers reserve the right to modify these rules or cancel the contest at any time if necessary to ensure fair play or comply with regulations.
4. By participating in the contest, all participants agree to these rules and the decisions of the contest organizers, which are final and binding.

## Disclaimer

Trading cryptocurrencies carries a high level of risk, and may not be suitable for all investors. The high degree of leverage can work against you as well as for you. Before deciding to trade cryptocurrencies, you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with cryptocurrency trading, and seek advice from an independent financial advisor if you have any doubts.

Participation in this contest does not guarantee profits and can result in losses. The contest organizers and ACE Community are not responsible for any losses incurred during the contest.
  `;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-b from-purple-900 via-gray-900 to-black text-white' : 'bg-gradient-to-b from-purple-100 via-gray-100 to-white text-gray-900'} transition-colors duration-300 flex flex-col justify-center items-center p-4`}>
      <motion.form
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-2xl w-full ${isDarkMode ? 'bg-gray-800 bg-opacity-75' : 'bg-white'} rounded-lg shadow-xl p-8`}
        onSubmit={handleSubmit}
      >
        <h2 className={`text-3xl font-bold mb-6 text-center text-transparent bg-clip-text ${isDarkMode ? 'bg-gradient-to-r from-blue-400 to-purple-500' : 'bg-gradient-to-r from-blue-600 to-purple-700'}`}>
          ACE Community Shitcoin Trading Contest Registration
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-bold mb-2" htmlFor="firstName">
              <div className="flex items-center">
                <UserCircle className={`mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                First Name
              </div>
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
              id="firstName"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && <p className="text-red-500 text-xs italic mt-1">{errors.firstName}</p>}
          </div>
          <div>
            <label className="block text-sm font-bold mb-2" htmlFor="lastName">
              <div className="flex items-center">
                <UserCircle className={`mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                Last Name
              </div>
              </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
              id="lastName"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <p className="text-red-500 text-xs italic mt-1">{errors.lastName}</p>}
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            <div className="flex items-center">
              <Mail className={`mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
              Email Address
            </div>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="text-red-500 text-xs italic mt-1">{errors.email}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="whatsappNumber">
            <div className="flex items-center">
              <Phone className={`mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
              WhatsApp Number
            </div>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
            id="whatsappNumber"
            type="tel"
            name="whatsappNumber"
            value={formData.whatsappNumber}
            onChange={handleChange}
            required
          />
          {errors.whatsappNumber && <p className="text-red-500 text-xs italic mt-1">{errors.whatsappNumber}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">
            <div className="flex items-center">
              <TrendingUp className={`mr-2 ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`} />
              Trading Style
            </div>
          </label>
          <div className="flex justify-between">
            {['Aggressive', 'Moderate', 'Conservative'].map((style) => (
              <label key={style} className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="tradingStyle"
                  value={style}
                  checked={formData.tradingStyle === style}
                  onChange={handleChange}
                />
                <span className="ml-2">{style}</span>
              </label>
            ))}
          </div>
          {errors.tradingStyle && <p className="text-red-500 text-xs italic mt-1">{errors.tradingStyle}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="experience">
            Level of experience with shitcoin trading (1-5)
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
            id="experience"
            type="number"
            name="experience"
            min="1"
            max="5"
            value={formData.experience}
            onChange={handleChange}
            required
          />
          {errors.experience && <p className="text-red-500 text-xs italic mt-1">{errors.experience}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="solWallet">
            <div className="flex items-center">
              <Wallet className={`mr-2 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-600'}`} />
              Solana Wallet Address (Must be a new, empty wallet)
            </div>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
            id="solWallet"
            type="text"
            name="solWallet"
            value={formData.solWallet}
            onChange={handleChange}
            required
          />
          {errors.solWallet && <p className="text-red-500 text-xs italic mt-1">{errors.solWallet}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="txHash">
            <div className="flex items-center">
              <Hash className={`mr-2 ${isDarkMode ? 'text-pink-400' : 'text-pink-600'}`} />
              Send Exactly 0.05 SOL to F6MYg38wN8juykKvHv6da1w82xqMF43bGjbR4cMS89et <br></br>
              Submit Transaction Hash/Signature (Tx Hash/Signature Only Not Solscan Link)
            </div>
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-700 border-gray-300'}`}
            id="txHash"
            type="text"
            name="txHash"
            value={formData.txHash}
            onChange={handleChange}
            required
          />
          {errors.txHash && <p className="text-red-500 text-xs italic mt-1">{errors.txHash}</p>}
        </div>

        <div className="mb-6">
          <button
            type="button"
            onClick={() => setShowRulesModal(true)}
            className={`text-sm underline ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}`}
          >
            Click to Read Full Contest Rules
          </button>
          <div className="mt-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="agreeToRules"
                checked={formData.agreeToRules}
                onChange={handleChange}
                className="form-checkbox"
              />
              <span className="ml-2 text-sm">I have read and agree to the contest rules</span>
            </label>
            {errors.agreeToRules && <p className="text-red-500 text-xs italic mt-1">{errors.agreeToRules}</p>}
          </div>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={`w-full font-bold py-3 px-4 rounded-full text-lg transition duration-300 shadow-lg ${
            isFormValid() && !isSubmitting
              ? isDarkMode
                ? 'bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white'
                : 'bg-gradient-to-r from-blue-600 to-purple-700 hover:from-blue-700 hover:to-purple-800 text-white'
              : 'bg-gray-400 text-gray-700 cursor-not-allowed'
          }`}
          type="submit"
          disabled={!isFormValid() || isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Registration'}
        </motion.button>

        {submitError && (
          <p className="text-red-500 text-sm mt-4 text-center">{submitError}</p>
        )}

        {submitSuccess && (
          <p className="text-green-500 text-sm mt-4 text-center">Registration successful! Thank you for joining the contest.</p>
        )}
      </motion.form>
      
      <div className="mt-8 text-center">
        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          By registering, you acknowledge that you have read and understood the full contest rules and the associated risks of cryptocurrency trading.
        </p>
      </div>

      <RulesModal isOpen={showRulesModal} onClose={() => setShowRulesModal(false)} contestRules={contestRules} />
    </div>
  );
};

export default RegistrationForm;
